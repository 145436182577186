// Slick-slider fixes

// Hide other slides if Slick doesn't work
.slider {
    &:not(.slick-initialized) .slide-item:not(:first-child) {
        display: none;
    }
}

.slider-lazy {
    &:not(.slick-initialized) {
        @extend .row;

        .col-4:nth-child(n+4) {
            display: none;
        }

        .col-2:nth-child(n+7) {
            display: none;
        }
    }
}

[class^="slider"] {

    // Hide overflowing content
    .slide-item,
    .slick-slider {
        overflow: hidden;
    }

    .slide-item {
        position: relative;
    }

    // Draggable styling
    .slick-list.draggable .slide-item {
        cursor: -webkit-grab;
        cursor: grab;

        &:active {
            cursor: -webkit-grabbing;
            cursor: grabbing;
        }

        &:only-child {
            cursor: auto;
        }
    }

    .slick-list:not(.draggable) .slide-item {
        -webkit-user-select: text;
        -moz-user-select: text;
        -ms-user-select: text;
        user-select: text;
    }

    // Arrows
    .slick-prev,
    .slick-next {
        height: ($spacer*2);
        width: ($spacer*2);
        z-index: 98;

        &::before {
            font-size: ($spacer*2);
            font-family: 'base';
            color: $body-color;
            display: block;
        }
    }

    .slick-prev {
        left: $spacer;

        &::before {
            content: "\e007"; // chevron-left
        }
    }

    .slick-next {
        right: $spacer;

        &::before {
            content: "\e008"; // chevron-right
        }
    }

    &.slick-outsidearrows {
        .slick-prev {
            left: (-$spacer*3);
        }
        .slick-next {
            right: (-$spacer*3);
        }
    }

    // Dots
    &.slick-dotted {
        margin-bottom: 5rem !important;
    }

    &.slick-inside-dots.slick-dotted {
        margin-bottom: 0 !important;

        .slick-dots {
            z-index: 99;
            bottom: 0;
        }
    }

    $slick-dots-size: 0.9rem;

    .slick-dots {
        bottom: -3rem;

        &.inside-dots {
            margin: 0 0 ($spacer*1.5);
            position: absolute;
        }

        li {
            margin: 0 ($slick-dots-size/2);
            height: $slick-dots-size;
            width: $slick-dots-size;

            button {
                text-align: center;
                height: $slick-dots-size;
                width: $slick-dots-size;
                padding: 0;

                &::before {
                    font-size: $slick-dots-size;
                    height: $slick-dots-size;
                    width: $slick-dots-size;
                    opacity: 0.25;
                }
            }

            &.slick-active {

                button {

                    &::before {
                        opacity: 1;
                    }
                }
            }
        }
    }
}
