html.mobile {
    &.mobile-menu-opened,
    &.mobile-menu-opened body {
        overflow-y: hidden;
    }
}

.header-section {

    // Desktop
    .mobile-menu {
        position: relative;

        > ul {
            transition: 800ms opacity ease-in;
            display: none;
            opacity: 0;
        }
    }

    // Mobile
    &.mobile {

        // show hamburger button
        .btn-mobile-menu {
            display: block;
            z-index: 999;
        }

        // prepare mobile menu elements
        .mobile-menu {

            .mobile-menu-background {
                height: 20px;
                width: 20px;
                position: fixed;
                top: 0;
                left: 0;
                border-radius: 50%;
                background: #fff;
                opacity: 0;
                transition: all 300ms cubic-bezier(0.000, 0.995, 0.990, 1.000);
            }

            > ul > li {
                opacity: 1;
                margin-bottom: 20px;
                z-index: 99999;
                position: relative;
                padding-top: 20px;

                &:first-child {
                    padding-top: inherit;
                }

                // line seperator
                &:after {
                    content: '';
                    height: 1px;
                    border-top: 1px solid #eee;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    width: 80%;
                    margin: auto;

                }

                &:first-child:after {
                    display: none;
                }

                // main menu
                .header-menu {
                    text-align: center;
                    margin: 0 auto;
                    float: none !important;

                    ul {
                        float: none !important;
                    }

                    li {
                        float: none !important;

                        .submenu {
                            white-space: nowrap;

                            ul {
                                white-space: normal;
                            }
                        }

                        a {
                            font-size: 18px;
                            text-transform: uppercase;
                            text-decoration: none;
                            letter-spacing: 1px;
                        }

                        .icon-chevron-small-down {
                            margin-right: -1.6rem;
                        }

                        a:hover,
                        &.active > a,
                        &.active > .submenu > a {
                            color: $primary !important;
                        }
                    }

                    li.active a {
                        font-weight: 400;
                    }

                    .submenu:hover > a {
                        background: none;
                    }
                }

                // social media
                &.menu-social {
                    text-align: center;

                    .socialmedia {
                        position: relative;

                        i {
                            color: $primary;
                        }
                    }
                }
            }
        }
    }


    // -- mobile expanded
    &.expand {
        overflow: scroll;
        height: 100vh;
        z-index: 999;
        color: $font;

        // show & animate mobile menu elements
        .mobile-menu {
            width: 100%;

            > ul {
                transition: 800ms opacity ease-in 800ms;
                display: block;
                margin: 40px auto 0;
                padding: 20px;
                list-style: none;
                opacity: 1;
                z-index: 2;
            }

            .nav {
                display: block;
                flex-wrap: nowrap;
                text-align: center !important;

                li {
                    display: block !important;
                    margin-right: 0 !important;

                    > a,
                    .submenu > a {
                        display: inline-block;
                        margin-bottom: 4px;
                        background: none;

                        &::before {
                            display: none;
                        }
                    }

                    ul {
                        margin-bottom: 4px !important;
                    }
                }
            }

            .mobile-menu-background {
                opacity: 1;
                width: 300vw;
                height: 300vh;
                top: -150vh;
                left: -150vw;
                transition: all 800ms cubic-bezier(0.000, 0.995, 0.990, 1.000);
            }

            ul {
                > li {
                    > .submenu {
                        ul.layer-2 {
                            background: none !important;
                            position: relative !important;
                            top: auto !important;
                            left: auto !important;
                            z-index: 0 !important;
                            padding: 0;
                            margin: 0;

                            a {
                                font-size: 15px;
                            }
                        }
                    }

                    &:not(.active) {
                        > .submenu {
                            ul.layer-2 {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}


// Hamburger menu
.btn-mobile-menu {
    position: absolute;
    cursor: pointer;
    display: none;
    left: $spacer;
    height: 26px;
    width: 26px;
    top: 8px;

    .icon {
        cursor: pointer;
    }

    // TODO: Classes hernoemen zeggen nu niks
    .x, .y, .z {
        position: absolute;
        background: #fff;
        border-radius: 2px;
        transition: all 200ms ease-out;
        height: 3px;
        width: 26px;
    }

    .x {
        top: 0;
    }

    .y {
        top: 11px;
    }

    .z {
        top: 22px;
    }

    .collapse {
        top: 10px;
        background: $primary;
        transition: all 70ms ease-out;

        &:not(.show) {
            display: block;
        }
    }

    .rotate30 {
        transform: rotate(30deg);
        transition: all 50ms ease-out;
    }

    .rotate150 {
        transform: rotate(150deg);
        transition: all 50ms ease-out;
    }

    .rotate45 {
        transform: rotate(45deg);
        transition: all 100ms ease-out;
    }

    .rotate135 {
        transform: rotate(135deg);
        transition: all 100ms ease-out;
    }
}
