// Slider example
.slider-example {
    overflow: visible !important;

    .slick-list,
    .slick-track {
        overflow: visible;
    }

    .slide-item {
        transition: 300ms all ease-in;
        position: relative;
        padding: 0;

        .slider-example-caption {
            transition: 300ms opacity ease-in;
            top: 50%; left: 50%;
            position: absolute;
            max-width: calc(100% - (#{$spacer}*2));
            padding: $spacer;
            background: $white;
            box-shadow: 0 0 ($spacer/2) rgba(0,0,0,0.25);
            border-radius: $border-radius;
            transform: translate(-50%,-50%);
            display: block;
            opacity: 0;
        }

        &:hover {
            box-shadow: 0 0 ($spacer/2) rgba(0,0,0,0.15);
            border-radius: $border-radius;
            transform: scale(1.075);
            z-index: 99;

            .slider-example-caption {
                opacity: 1;
            }
        }
    }
}
