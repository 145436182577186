// Dev grid
.dev-grid {
    top: $spacer; right: $spacer;
    position: fixed;
    z-index: 9002;
    background: $white;
    border-radius: $border-radius;
    padding: ($spacer/3) ($spacer);
    box-shadow: 0 0 ($spacer/2) rgba(0,0,0,0.1);
    opacity: 0.2;

    &:hover {
        opacity: 1;
    }

    .dev-grid-toggle {
        position: relative;
        z-index: 3;

        &:checked {
            ~ .dev-grid-container {
                display: block;
            }
        }
    }

    .dev-grid-label {
        position: relative;
        user-select: none;
        z-index: 3;
    }

    .dev-grid-container {
        top: 0; left: 0; right: 0;
        pointer-events: none;
        position: fixed;
        z-index: 1;
        display: none;
    }

    .container,
    .container-xl {
        position: relative;

        [class^="col"] {
            position: relative;

            &::before {
                background: rgba(0,0,0,0.1);
                display: block;
                height: 100vh;
                content: '';
                width: 100%;
            }
        }
    }
}
