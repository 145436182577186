[data-menu-measure="child"] {
    white-space: nowrap;
}

.header-section {
    @extend .pt-3;
    box-shadow: 0 8px 6px -6px rgba(0,0,0,0.1);
    background-color: #ffffff;
    position: relative;
    z-index: 5;

    .cookies-section ~ & {
        top: 0; left: 0; right: 0;
        position: absolute;
    }

    @media all and (max-width: 910px) {
        height: 115px;
        padding-top: 0 !important;
    }

    @media all and (max-width: 470px) {
        height: 135px;
    }

    .container,
    .container-xl {
        position: relative;
    }

    .mobile-menu,
    .btn-mobile-menu {
        &.opened ~ * {
            display: none !important;
        }
    }

    .mobile-menu {
        z-index: 998;
    }

    .logo-container {
        .logo-normal {
            width: 275px;

            @media all and (max-width: 470px) {
                max-width: 300px !important;
            }
        }

        @media all and (max-width: 910px) {
            position: absolute;
            top: 50px;
            left: 50%;
            transform: translateX(-50%);
        }

        @media all and (max-width: 470px) {
            top: 55px;
        }
    }

    .usp-container {
        @extend .ml-auto;
        padding-top: 10px;
        margin-left: $spacer*2;
        margin-bottom: 0;
        font-family: $headings-font-family;
        font-weight: 400;
        font-size: 13px;
        line-height: 22px;
        color: $tertiary;

        @media all and (max-width: 1120px) {
            margin-bottom: 10px;
        }

        @media all and (max-width: 910px) {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            text-align: center;
            background: $tertiary;
            padding-bottom: 10px;
        }

        .slider-usps {
            display: none;
            text-align: center;

            @media all and (max-width: 910px) {
                display: block;
            }
        }

        .list-inline {
            @media all and (max-width: 910px) {
                display: none;
            }
        }

        li {
            margin-right: $spacer;
        }

        p {
            margin-bottom: 0;
        }

        span {
            @media all and (max-width: 1132px) {
                display: none;
            }
        }

        .title {
            font-weight: 700;
            font-style: italic;
            font-size: 20px;
            color: $primary;

            @media all and (max-width: 1132px) {
                font-size: 16px;
            }

            @media all and (max-width: 910px) {
                font-size: 12px;
            }
        }

        svg {
            margin-right: $spacer;
            max-width: 40px;
            height: 30px;
            width: auto;

            @media all and (max-width: 910px) {
                max-width: 30px;
                height: 20px;
                width: auto;

                path, rect {
                    fill: $white;
                }
            }
        }
    }

    .container,
    .container-xl {
        display: flex;
    }
}
