// Slider
.slider-section {
    position: relative;
    background: $white;

    .wrapper {
        position: relative;
        max-width: 1395px;
        margin: auto;
    }

    .slide-image {
        float: right;

        @media all and (max-width: 350px) {
            margin-right: -100%;
            margin-left: -100%;
            max-width: 300%;
        }
    }

    // Navigation color
    [class^="slider"] .slick-next::before,
    [class^="slider"] .slick-prev::before {
        text-shadow: 0 0 $spacer rgba(0,0,0,0.5);
        color: $white;
    }

    // Dots color
    .slick-dots li button::before,
    .slick-dots li.slick-active button::before {
        text-shadow: 0 0 $spacer rgba(0,0,0,0.5);
        color: $white;
    }

    .slide-content {
        top: 0; bottom :0;
        position: absolute;
        left: 0; right: 0;
        z-index: 2;

        @media all and (max-width: 875px) {
            position: relative;
            top: auto; bottom: auto;
            left: auto; right: auto;
            overflow: auto;
            background: $primary;
        }

        .overlay-primary {
            position: absolute;
            top: 0; bottom: 0;
            left: 0;
            width: 580px;
            background: $primary;

            @media all and (max-width: 1200px) {
                width: 450px;
            }

            @media all and (max-width: 875px) {
                display: none;
            }

            .skew-border {
                border-bottom: 405px solid transparent;
                border-left: 25px solid #fff;
                position: absolute;
                z-index: 9;
                bottom: 0;
                top: 0;
                right: -150px;
                border-left-color: $primary;
                border-left-width: 150px;
            }
        }

        .container,
        .container-xl {

            .slide-caption {
                user-select: text;
                padding: 40px 0 0;
                float: left;
                font-family: $headings-font-family;
                font-weight: 400;
                color: $tertiary;
                position: relative;
                z-index: 5;
                max-width: 420px;

                @media all and (max-width: 1200px) {
                    padding-left: 10px;
                }

                @media all and (max-width: 875px) {
                    max-width: 100%;
                    background-color: $primary;
                    padding: 40px 10px 20px;
                }

                .main-title {
                    font-size: 20px;
                    font-weight: 700;
                    font-style: italic;
                    line-height: 35px;
                    text-transform: uppercase;
                    color: $white;
                    position: relative;
                    margin-bottom: 30px;

                    &::after {
                        content: '';
                        position: absolute;
                        bottom: -20px;
                        left: 0;
                        background-color: $white;
                        height: 5px;
                        width: 120px;
                    }

                    .number {
                        font-weight: 900;
                        font-size: 90px;

                        @media all and (max-width: 510px) {
                            font-size: 60px;
                        }
                    }

                    .discount {
                        font-size: 50px;
                        color: $tertiary;

                        @media all and (max-width: 875px) {
                            display: inline-block;
                            margin-left: 10px;
                        }

                        @media all and (max-width: 510px) {
                            font-size: 30px;
                        }
                    }
                }

                .subtitle {
                    margin-bottom: 10px;
                    font-size: 30px;
                    font-weight: 700;
                    font-style: italic;
                    line-height: 35px;
                    text-transform: uppercase;

                    @media all and (max-width: 510px) {
                        font-size: 25px;
                        line-height: 30px;
                    }
                }

                .btn-secondary {
                    font-size: 17px !important;
                    font-weight: 700 !important;
                    text-transform: uppercase;
                }
            }
        }
    }

    .slick-inside-dots .slide-content {
        bottom: ($spacer*4);
    }
}
