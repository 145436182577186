// Highlight
.highlight-section {
    .container,
    .container-xl {
        position: relative;
        height: 100%;
    }

    h1 {
        bottom: ($spacer*2);
        position: absolute;
        left: $spacer;
    }
}
