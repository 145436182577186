@charset "utf-8";

@import '_variables.scss';

// Third party
@import 'bootstrap/scss/bootstrap';
@import '../../fonts/base/styles';

// Site
@import 'fonts';
@import 'global';
@import 'elements/alert';
@import 'elements/breadcrumb';
@import 'elements/list-group';
@import 'elements/slick-slider';
@import 'elements/form-feedback';
@import 'components/contact-box';
@import 'components/dev-grid';
@import 'components/cookies-card';
@import 'components/error-page';
@import 'components/cookies-section';
@import 'components/header-section';
@import 'components/footer-section';
@import 'components/slider-section';
@import 'components/searchbar-section';
@import 'components/input-group-inside';
@import 'components/mobile-menu';
@import 'components/google-maps';
@import 'components/highlight-section';
@import 'components/slider-example';
@import 'components/slider-media';


// General styling
body {
    font-family: $content-font-family;
    color: $font;
}


// Headings
h1, h2, h3, h4, h5, h6 {
    font-family: $headings-font-family;
    font-weight: 700;
    color: inherit;
}

h1, .h1,
h2, .h2 {
    font-weight: 700;
    font-size: 40px;
    text-transform: uppercase;
    color: $tertiary;
    position: relative;
    margin-bottom: 30px;

    &::after {
        content: '';
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        background-color: $primary;
        height: 5px;
        width: 120px;
    }
}

h3, .h3 {
    font-family: 'Roboto Slab', serif;
    font-weight: 400;
    font-size: 18px;
    color: #7c7c7c;
}


// Background colors
.bg-light {
    background: #f9f9f9;
    border-bottom: 5px solid $primary;
}


// Buttons
.btn {
    padding-bottom: 2px !important;
    font-family: $headings-font-family;
    font-weight: 400 !important;
    font-size: 20px;

    &::after {
        font-family: 'base';
        font-size: 22px;
        vertical-align: middle;
        content: '\e000';
        margin-left: 10px;
    }
}

.btn-primary {
    font-weight: 700 !important;
    text-transform: uppercase;
    color: $white;
    border-bottom: 4px solid #a26902;

    &:hover {
        color: $white !important;
    }
}

.btn-secondary {
    border-bottom: 4px solid #088235;

    strong {
        text-decoration: underline;
    }
}


// Products
.products {
    max-width: 1250px;

    .slider-products {
        margin-top: $spacer*1.5;
        padding: 0 40px;

        .slide-item {
            padding-top: 40px;
            overflow: initial;
        }

        .slick-prev {
            &::before {
                content: '\e001' !important;
            }
        }

        .slick-next {
            &::before {
                content: '\e000' !important;
            }
        }
    }

    .card {
        @extend .mx-4;
        font-family: $headings-font-family;
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;
        border: 0;
        position: relative;

        .card-body {
            padding-top: 10px;
        }

        .price {
            position: absolute;
            top: -40px;
            right: -20px;
            padding-top: 20px;
            font-size: 17px;
            line-height: 30px;
            color: $white;
            width: 110px;
            height: 110px;
            background-color: $secondary;
            border-radius: 50%;

            span.euro {
                font-size: 30px;
            }

            span.number {
                font-size: 35px;
            }
        }
    }
}


// Concept
.concept {
    max-width: 1395px;
    color: $tertiary;

    .bg-primary {
        padding: 80px 40px 80px 145px;

        @media all and (max-width: 1200px) {
            padding-left: 40px;
        }
    }

    .bg-image {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        @include media-breakpoint-down(md) {
            background: $white !important;
            padding: 0;
        }
    }

    .concept-mobile {
        display: none;

        @include media-breakpoint-down(md) {
            display: block;
        }
    }

    h2 {
        font-size: 52px;

        &::after {
            transform: none;
            left: 0;
            background-color: $white;
        }
    }

    .btn {
        margin-top: 20px;
        font-weight: 700 !important;
    }
}

// Options
.options {
    h2 {
        font-size: 30px;

        &::after {
            display: none;
        }
    }

    ul {
        font-size: 15px;
        line-height: 22px;
        list-style: none;
        padding-left: 0;

        li {
            margin-left: 20px;

            &::before {
                content: '•';
                font-size: 22px;
                color: $primary;
                vertical-align: middle;
                margin-left: -20px;
                padding-right: 10px;
            }
        }
    }
}


// USPs extra info
.usps-extra {
    @media all and (max-width: 1200px) {
        padding-left: 20px;
        padding-right: 20px;
    }

    .card {
        text-align: center;
        border: 0;
        border-radius: 0;
        box-shadow: 0 0 10px rgba(0,0,0,0.1);
        -webkit-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;

        &:hover {
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
            position: relative;
            z-index: 3;
        }
    }

    .card-header {
        padding-top: 30px;
        padding-bottom: 20px;
        font-family: $headings-font-family;
        font-weight: 700;
        font-size: 16px;
        color: $tertiary;
        border: 0;
        border-radius: 0;
        background-color: $primary;

        p {
            margin-bottom: 0;
        }

        .title {
            font-style: italic;
            font-size: 23px;
            color: #fff;
        }
    }

    .card-body {
        background-color: #f9f9f9;
    }

    svg {
        height: 40px;
        margin-bottom: $spacer/2;
    }

    #price {
        height: 40px;
    }
}

.content-simple {
    h1 {
        &::after {
            left: 0;
            transform: none;
        }
    }
}

.content-detail {
    h2 {
        margin-top: 50px;
        font-size: 35px;
        line-height: 40px;
    }

    h1, h2 {
        &::after {
            left: 0;
            transform: none;
        }
    }

    h3 {
        font-family: 'Roboto Slab', serif;
        font-weight: 400;
        font-size: 18px;
        color: $primary;
    }

    figure {
        @media all and (max-width: 740px) {
            margin: 25px 0 !important;
            float: none !important;
        }
    }
}
