// Searchbar
#searchbar-section {
    background-color: darken($primary,5%);
    transition: 200ms all ease-in;
    margin-top: -86px; // Dirty
    position: relative;
    overflow: hidden;
    z-index: -1;

    &.active {
        margin-top: 0;
        z-index: 98;
    }

    button {
        i {
            display: flex;
        }
    }
}

.searchbar-toggle {
    cursor: pointer;
}
