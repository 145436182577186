// Footer
.footer-section {
    font-family: $headings-font-family;
    font-weight: 700;
    font-size: 14px;
    background-color: #efefef;

    .logo-block {
        width: 200px;

        .logo {
            display: block;
        }
    }

    .logo-text {
        margin-top: $spacer;
    }

    .socialmedia {
        float: left;
        margin-top: 5px;

        i {
            font-size: 35px;
            color: #005a9f;
        }
    }

    .address {
        @include media-breakpoint-down(sm) {
            margin-top: $spacer;
        }

        .logo {
            display: inline-block;

            svg {
                width: 35px;
                height: 45px;
            }
        }
    }

    .footer-menu {
        @extend .list-inline;

        a {
            @extend .link-inverted;
        }

        li::after {
            content: '|';
            padding-left: 10px;
            color: $font;
        }

        li:last-child {
            &::after {
                display: none;
            }
        }
    }

    .copyright {
        font-weight: 400;
        font-size: 13px;
    }

    .text-right {
        @include media-breakpoint-down(sm) {
            text-align: left !important;
        }
    }
}
