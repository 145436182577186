$grid-gutter-width:                     2rem;


// Link
$link-color:                            inherit;
$link-decoration:                       underline;
$link-hover-color:                      inherit;
$link-hover-decoration:                 none;

// Fonts
$content-font-family:                   'Open Sans', serif;
$headings-font-family:                  'Exo', sans-serif;

// Colors
$primary:                               #fcb01a;
$secondary:                             #0aa644;
$tertiary:                              #000000;
$success:                               #5bbd72;
$info:                                  #3b83c0;
$warning:                               #ebb141;
$danger:                                #d95c5c;
$default:                               #cccccc;
$font:                                  #555555;

$theme-colors: (
    "primary":                          $primary,
    "secondary":                        $secondary,
    "tertiary":                         $tertiary,
    "success":                          $success,
    "info":                             $info,
    "warning":                          $warning,
    "danger":                           $danger,
    "default":                          $default,
    "font":                             $font,
);


// Form
$input-focus-box-shadow:                none;
$btn-focus-box-shadow:                  none;


// Breadcrumb
$breadcrumb-bg:                         #fff;

$breadcrumb-padding-y:                  0;
$breadcrumb-padding-x:                  0;
$breadcrumb-margin-bottom:              2rem;
