#error-404 {
    background: #f5f5f5;
}

.error-header {
    border-bottom: 5px solid darken($primary,7%);
    top: 0; left: 0; right: 0;
    padding: 0 ($spacer/2);
    background: $primary;
    position: fixed;
    height: 50%;

    .error-message {
        bottom: 0; left: 0; right: 0;
        margin: 0 auto -21px;
        position: absolute;
        max-width: 450px;
        z-index: 3;

        .error-logo {
            padding: ($spacer*1.5) ($spacer*2);
            text-align: left;

            svg,
            img {
                max-height: ($spacer*4);
                margin-right: auto;
                max-width: 100%;
                width: auto;
            }
        }

        .error-title {
            border: 1px solid darken($primary,7%); border-bottom: 0;
            padding: $spacer ($spacer*2) 0;
            position: relative;
            background: #fff;

            h1,
            .h1 {
                padding-right: ($spacer*4);
                margin-bottom: $spacer;
                font-size: 1.5em;
                color: $primary;
            }

            span {
                top: $spacer; right: (-$spacer*2);
                position: absolute;
                font-size: 1.5em;
                color: $primary;
                margin: 0;
            }
        }
    }
}

.error-content {
    @extend .mb-lc-0;
    padding: ($spacer*1.25) ($spacer*2) ($spacer*1.5);
    border: 1px solid #ddd; border-top: 0;
    top: 50%; left: 0; right: 0;
    width: calc(100% - 16px);
    background: #fff;
    max-width: 450px;
    position: fixed;
    margin: 0 auto;
    z-index: 4;
}

@include media-breakpoint-down(md) {
    .error-header {
        position: relative;

        .error-message {
            position: relative;

            .error-logo {
                padding: $spacer;

                img,
                svg {
                    max-height: ($spacer*3);
                }
            }

            .error-title {
                padding: $spacer;
            }
        }
    }

    .error-content {
        padding: 0 $spacer $spacer;
        position: relative;
        top: 0;
    }
}

@include media-breakpoint-down(sm) {
    .error-header .error-message .error-title h1,
    .error-header .error-message .error-title .h1 {
        font-size: 18px !important;
    }
}
